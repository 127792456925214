<template>
    <div>
        <b-container class="mb-4">
            <b-row cols="12" class="p-2 py-4 mb-3 justify-content-center bg-sukudai-green">
                <b-col cols="2" class="w-100"
                       style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                <b-col cols="6">
                    <h2 class="text-white col-12 text-center font-weight-bolder">聯絡我們</h2>
                </b-col>
                <b-col cols="2" class="w-100"
                       style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
            </b-row>

            <!-- 第一區 -->
            <b-row class="mb-4">
                <b-col cols="12" lg="6" class="p-3 pl-4 bg-sukudai-light-grey">
                    <div class="ml-5 pl-5 border-left border-dark">
                        <p class="py-1 pl-2 pr-5 font-weight-bolder font-lg"
                           style="width: fit-content">
                            速可貸 SUPERPAY
                        </p>
                        <p>
                            服務地址：510 彰化縣員林市惠明街 313 號<br/>
                            營業時間：上午 09:00 到晚上 09:00<br/>
                            服務電話：0800558166 或 0983212666<br/>
                        </p>
                        <b-row>
                            <b-col cols="6" lg="4">
                                <b-button variant="warning" size="lg"
                                          class="text-white font-md font-weight-bolder py-1 mb-2"
                                          @click="$bvModal.show(modalId)">立即諮詢</b-button>
                                <b-button variant="warning" size="lg"
                                          class="text-white font-md font-weight-bolder py-1"
                                          :to="{ path: 'FormConsultation' }">
                                    表單諮詢
                                </b-button>
                            </b-col>

                            <b-col cols="3">
                                <a href="https://line.me/R/ti/p/@359xbcpd" target="_blank">
                                    <img :src="require('@/assets/icon/logo/line-brands.svg')" alt="Line" class="w-100">
                                </a>
                            </b-col>

                            <b-col cols="3">
                                <a href="https://www.facebook.com/100087320416002/" target="_blank">
                                    <img :src="require('@/assets/icon/logo/square-facebook-brands.svg')" alt="Facebook page" class="w-100">
                                </a>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>

                <b-col cols="12" lg="6">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3646.243437291479!2d120.56262521511486!3d23.951830386968044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693667a49d3171%3A0x59e01f8baf48aeb0!2zNTEw5b2w5YyW57ij5ZOh5p6X5biC5oOg5piO6KGXMzEz6Jmf!5e0!3m2!1szh-TW!2stw!4v1664009473333!5m2!1szh-TW!2stw"
                        width="100%" height="250" class="border-0" allowfullscreen="" loading="lazy"></iframe>
                </b-col>
            </b-row>

            <b-col cols="12" class="my-3 pt-4 bg-sukudai-green">
                <div class="mb-4 text-white text-center">
                    <span class="px-5 pb-3 font-weight-bolder font-xl border-bottom border-white text-center">
                        寫信給我們
                    </span>
                </div>
                <b-row class="py-3 px-2 text-center justify-content-center">
                    <b-col cols="12" lg="8">
                        <b-row>
                            <b-col cols="12" lg="6" class="mb-4">
                                <b-form-input v-model="name" placeholder="您的大名" class="border border-dark"></b-form-input>
                            </b-col>

                            <b-col cols="12" lg="6" class="mb-4">
                                <b-form-input v-model="tel" placeholder="聯絡電話" class="border border-dark"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" lg="8">
                        <b-row>
                            <b-col cols="12" lg="6" class="mb-4">
                                <b-form-input v-model="email" placeholder="電子信箱" class="border border-dark"></b-form-input>
                            </b-col>

                            <b-col cols="12" lg="6" class="mb-4">
                                <b-form-input v-model="line" placeholder="LINE ID" class="border border-dark"></b-form-input>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col cols="12" lg="8" class="mb-4">
                        <b-form-textarea
                            id="textarea-large"
                            size="lg"
                            placeholder="請輸入信件內容"
                            v-model="content"
                            class="border border-dark"
                        ></b-form-textarea>
                    </b-col>

                    <b-col cols="12" class="row justify-content-center">
                        <b-button @click="sendContact()" variant="warning" size="lg"
                                  class="text-white font-weight-bold font-xl px-5">送出
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

            <b-modal :id="modal.id" :title="modal.title" hide-footer>
                <p class="my-4" v-text="modal.content"></p>
                <b-button @click="$bvModal.hide(modal.id)">關閉</b-button>
            </b-modal>
        </b-container>

        <b-modal :id="modalId" hide-footer>
            <quick-submit/>
        </b-modal>
    </div>
</template>

<script>
import quickSubmit from "@/components/quickSubmit";

export default {
    components: {
        quickSubmit
    },

    data() {
        return {
            name: '',
            tel: '',
            email: '',
            line: '',
            content: '',
            modal: {
                id: 'popup-message',
                title: '立即諮詢正在開發中，請稍後',
                content: '立即諮詢正在開發中，請稍後',
            },
            modalId: 'contact-now-modal',
        }
    },
    methods: {
        sendContact() {
            this.axios({
                method: 'POST',
                url: 'https://script.google.com/macros/s/AKfycbwHG6DRacRRfRAzPCs4bOAAlvWOsiQNDcxF-ItolOrGEya83elVNXuFkylH368_5gJf/exec',
                data: JSON.stringify({
                    type: '寫信給我們',
                    name: this.name,
                    tel: this.tel,
                    email: this.email,
                    line: this.line,
                    content: this.content,
                }),
                mimeType: 'application/JSON'
            }).then(() => {
                this.modal.title = '傳送成功';
                this.modal.content = '已收到您的諮詢請求，已將訊息登入至系統，我們將會盡快與您聯絡';
                this.$bvModal.show(this.modal.id);
                this.clearData();
            }).catch(err => {
                this.modal.title = '傳送失敗';
                this.modal.content = `請聯絡系統管理員。`;
                console.log(err);
                this.$bvModal.show(this.modal.id);
                this.clearData();
            });
        },

        clearData() {
            this.name = '';
            this.tel = '';
            this.email = '';
            this.line = '';
            this.content = '';
        }
    }
}
</script>
